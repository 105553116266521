import React, { Component } from "react";
import { Button, Linking, ScrollView, Text, useWindowDimensions } from "react-native";
import { CommonActions, createNavigationContainerRef, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import MainScreen from "./screens/MainScreen";
import VideoScreen from "./screens/VideoScreen";
import ChannelScreen from "./screens/ChannelScreen";
import CategoryScreen from "./screens/CategoryScreen";
import CategoryVideoUploadScreen from "./screens/CategoryVideoUploadScreen";
import EncodeElementScreen from './screens/EncodeElementScreen';
import * as WebBrowser from 'expo-web-browser';
import { LogBox } from "react-native";
import * as config from './config/config.json';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
const firebaseConfig = {

  apiKey: "AIzaSyBb5-NkOnMFVl6J5pz8LjjjfTRCMvDCtbw",

  authDomain: "cktest-3fd5b.firebaseapp.com",

  projectId: "cktest-3fd5b",

  storageBucket: "cktest-3fd5b.appspot.com",

  messagingSenderId: "239960333256",

  appId: "1:239960333256:web:c205ae29c3408aed65befd",

  measurementId: "G-CR4HSH37J0"

};

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/userinfo.email');

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

signInWithPopup(auth, provider).then(
  (result) => {
    console.log("SignWithPopup: ", result);
  }
).catch((error) => {
  const errorCode = error.code;
  const errorMessage = error.message;
  // The email of the user's account used.
  const email = error.customData.email;
  // The AuthCredential type that was used.
  const credential = GoogleAuthProvider.credentialFromError(error);

  console.log("Google signing error: ", error);
})

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider
} from "@apollo/client";
import { TubeUser } from "./types";
import { env } from "process";

const link = createHttpLink({
  uri: config.graphql_endpoint,
  credentials: 'include'
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link
});

export var loggedInUser : TubeUser | undefined;

const navigationRef = createNavigationContainerRef()

LogBox.ignoreLogs(["EventEmitter.removeListener"]);
WebBrowser.maybeCompleteAuthSession();

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: [
    "http://localhost:3000"
  ],
  config: {
    screens: {
      Main: { path: "/"},
      Video: { path: "video/:name" },
      Channel: { path: "channel/:id"},
      Category: { path: "category/:category_id"},
      CategoryVideoUpload: { path: "category/:category_id/upload_video"},
      EncodeElement: { path: "element/:category_id/encode"}
    },
  },
};

type TubeProps = {}

type TubeState = {
  loggedIn: boolean
}

export default class App extends Component<TubeProps, TubeState> {
  /*const [request, response, promptAsync] = Google.useAuthRequest({
    responseType: "id_token",
    expoClientId: '501313704920-kt06dndc8l48fhl67fuocr6ljs2vuq90.apps.googleusercontent.com',
    iosClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    androidClientId: 'GOOGLE_GUID.apps.googleusercontent.com',
    webClientId: '501313704920-kt06dndc8l48fhl67fuocr6ljs2vuq90.apps.googleusercontent.com',
  });
*/
  /*React.useEffect(() => {
    if (response?.type === 'success') {
      const id_token = response.params.id_token;
      console.log("id_token: ", response.params.id_token);
      login(id_token);
    }
  }, [response]);
*/
  //<Stack.Navigator screenOptions={{headerRight: () => <a href={config.google_oauth_endpoint}><Button title="Login" /></a>}}>

  constructor(props: TubeProps) {
    super(props);
    this.state = { loggedIn: false }
  }
  
  componentDidMount() {
    console.log("App did mount");
    this.checkLoginStatus();
  }

  async checkLoginStatus() {
    
    console.log("Env: ", env.NODE_ENV);

    fetch(config.check_auth_endpoint, {
      credentials: "include"
    }).then((response) => {
      response.json().then((value) => {
        console.log("Response: ", value);
        if(value.user) {
          loggedInUser = value.user;
          this.setState({ loggedIn : true });
        }
        else {
          loggedInUser = undefined;
          this.setState({ loggedIn : false });
        }
      })
    }).catch((error) => {
      this.setState({ loggedIn : false });
      // TODO: handle error?
    })   
  }

  onLoginPressed() {
    if(this.state.loggedIn)
    {
      fetch(config.logout_endpoint, {
        credentials: "include"
      }).then((data) => {
        if(navigationRef.isReady())
        {
          navigationRef.dispatch(CommonActions.reset({
            index: 0,
            routes: [
              { name: 'Main' }
            ],
          }));
        }
        else 
        {
          console.log("Nav not ready")
        }
        
      })
    }
    else
    {
      Linking.openURL(config.google_oauth_endpoint);
    }    
  }

  render() {
    return (
      <ApolloProvider client={client}>
      <NavigationContainer ref={navigationRef} linking={linking} fallback={<Text>Loading...</Text>} >
        
        <Stack.Navigator screenOptions={{headerRight: () => <Button title={this.state.loggedIn ? "Logout" : "Login"} onPress={this.onLoginPressed.bind(this)}/>}}>
          <Stack.Screen name="Main" component={MainScreen}/>
          <Stack.Screen name="Video" component={VideoScreen} />
          <Stack.Screen name="Channel" component={ChannelScreen} />
          <Stack.Screen name="Category" component={CategoryScreen} />
          <Stack.Screen name="CategoryVideoUpload" component={CategoryVideoUploadScreen} />
          <Stack.Screen name="EncodeElement" component={EncodeElementScreen} />
        </Stack.Navigator>
      </NavigationContainer>
      </ApolloProvider>
    );
  }
}

/*function login(id_token: string) {
    var encodedKey = encodeURIComponent("idtoken");
    var encodedValue = encodeURIComponent(id_token);
    const formBody = (encodedKey + '=' + encodedValue);

    //POST request
    fetch(config.google_oauth_endpoint, {
      method: 'POST', //Request Type
      body: formBody, //post body
      headers: {
        //Header Defination
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    })
    .then((response) => response.json())
    //If response is in json then in success
    .then((responseJson) => {
      console.log(responseJson);
    })
    //If response is not in json then in error
    .catch((error) => {
      console.error(error);
    });
}*/
/*
export default class App extends Component<{},AppState> {

  
  constructor(props: any) {
    super(props);
    

    this.state = { promptAsync: promptAsync}
  }

  _signIn() {

  }

  render() {
    
  }
}
*/
