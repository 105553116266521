import React, {Component} from 'react';
import { Button, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import * as ChannelService from '../services/ChannelService';
import * as Types from '../types';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';
import * as App from '../App';
import * as LoggedInUserService from '../services/LoggedInUserService';
import Checkbox from 'expo-checkbox';
import { styles } from './styles';

export interface MainScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
};

type State = {
  channels?: Array<Types.TubeChannel>;
  new_channel_name: string;
  new_channel_description: string;
  new_channel_visibility: string;
}
export default class MainScreen extends Component<MainScreenProps, State> {

  constructor(props: MainScreenProps) {
    super(props)

    this.state={new_channel_name: "", new_channel_description: "", new_channel_visibility: "public"}
  }

  componentDidMount() {

    ChannelService.fetchChannels().then((data) => {
      
      this.setState({channels: data});
      //this.props.navigation.setOptions({ title: data.name});
    })

    

    this.props.navigation.setOptions({ headerLeft: () => { return <></>}
    });
  }

  renderComment(author: string, message: string) {

    return (
      <View>
        <View style={{ borderBottomColor: 'black', borderBottomWidth: 1}}/>
        <Text style={{ fontSize: 18, fontWeight: "bold"}}>
          {author}
        </Text>
        <Text style={{marginLeft: 32}}>{message}</Text>
        
      </View>
    )
  }

  renderChannelItem({item} : { item: Types.TubeChannel }) : any  {
    return (
      <TouchableOpacity onPress={() => this.props.navigation.navigate("Channel", { id: item.id })}>
        <View style={{ flexDirection: 'row', height: 120, alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, width: '90%', alignSelf: 'center'}}>
          { //<Image style={{ width: 200, height:112, resizeMode: 'contain' }} source={{ uri: item.image_url}}/>
          }
          <Text style={{ flex:1, textAlign: 'center', fontSize: 24 }}>{ item.name }</Text>
        </View>
      </TouchableOpacity>
    );
  }

  onSubmitNewChannel()
  {
    ChannelService.addChannel(this.state.new_channel_name, this.state.new_channel_description, this.state.new_channel_visibility);
  }

  render() {
    return (
      <ScrollView>
        <View style={styles.container}>
          <FlatList 
            contentContainerStyle={{width: '100%', alignSelf: 'center'}}
            style={{width: '100%'}}
            data={ this.state.channels}
            renderItem={this.renderChannelItem.bind(this)}
            keyExtractor={(item) => item.name}
          />
        </View>
        { LoggedInUserService.CanCreateChannel() &&
          <View style={styles.create_container}>
            <View style={styles.create_top}>
              <Text style={{width: 120}}>Channel Name</Text>
              <TextInput onChangeText={(text) => { this.setState({new_channel_name : text}); }} value={this.state.new_channel_name} style={{flex:1, borderWidth: 0.1, height: 32, borderColor: '#BBB', outline: 'none' }}></TextInput>
            </View>
            <View style={styles.create_middle}>
              <Text style={{width: 120}}>Description</Text>
              <TextInput onChangeText={(text) => { this.setState({new_channel_description : text}); }} value={this.state.new_channel_description} multiline={true} style={{flex:1, borderWidth: 0.1, height: 80, borderColor: '#BBB', outline: 'none' }}></TextInput>
            </View>
            <View style={styles.create_checkbox}>
              <Text style={{width: 120}}>Private</Text>
              <Checkbox onValueChange={(value) => { this.setState({new_channel_visibility : value ? "private" : "public"})}} value={this.state.new_channel_visibility == "public" ? false : true} ></Checkbox>
            </View>
            <Button disabled={App.loggedInUser == undefined} title="Add Channel" onPress={() => this.onSubmitNewChannel()} />
          </View>
        }
      </ScrollView>
    );
  }
}


