import React, {Component} from 'react';
import {Video} from 'expo-av';
import { Button, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import { Dimensions } from 'react-native';
import * as ChannelService from '../services/ChannelService';
import * as CategoryService from '../services/CategoryService';
import * as Types from '../types';
import {NativeStackNavigationProp} from '@react-navigation/native-stack';
import { RouteProp } from '@react-navigation/native';
import * as config from '../config/config.json';
import * as LoggedInUserService from '../services/LoggedInUserService';
import Checkbox from 'expo-checkbox';
import * as App from '../App';
import { styles } from './styles';

export interface ChannelScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
  name: string;
};

type State = {
  channelData?: Types.TubeChannel;
  ar: number;
  new_category_name: string;
  new_category_description: string;
  new_category_visibility: string;
}
export default class VideoScreen extends Component<ChannelScreenProps, State> {

  private _unsubscribeFocus : any = null;

  constructor(props: ChannelScreenProps) {
    super(props)

    this.state={ar: 1.77777777, channelData: undefined, new_category_name: "", new_category_description: "", new_category_visibility: "public"}
  }

  componentDidMount() {
    this._unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentWillUnmount() {
    if(this._unsubscribeFocus) {
      this._unsubscribeFocus();
    }
  }

  onFocus() {

    if(this.props.route.params) {
      /*if(this.props.route.params.name) {
        ChannelService.fetchChannelData(this.props.route.params.name).then((data) => {
          //console.log("Data: ", data);
          this.setState({channelData: data});
          this.props.navigation.setOptions({ title: data.name, headerLeft: 
            this.renderHeaderLeft.bind(this)
          });
        })
      }
      else */if(this.props.route.params.id) {
        ChannelService.fetchChannelDataByID(this.props.route.params.id).then((data) => {
          //console.log("Data: ", data);
          this.setState({channelData: data});
          this.props.navigation.setOptions({ title: data.name, headerLeft: 
            this.renderHeaderLeft.bind(this)
          });
        })
      }      
    }
  }

  renderHeaderLeft() {
    return (
      <TouchableOpacity onPress={() => this.props.navigation.navigate("Main")}><Image style={{ marginLeft: 16, width: 24, height: 24}} source={{"uri":config.image_endpoint + "/back_arrow"}}/></TouchableOpacity>
    )
  }
  renderComment(author: string, message: string) {

    return (
      <View>
        <View style={{ borderBottomColor: 'black', borderBottomWidth: 1}}/>
        <Text style={{ fontSize: 18, fontWeight: "bold"}}>
          {author}
        </Text>
        <Text style={{marginLeft: 32}}>{message}</Text>
        
      </View>
    )
  }

  renderCategoryItem({item} : { item: Types.TubeCategory }) : any  {
    return (
      <TouchableOpacity onPress={() => this.props.navigation.navigate("Category", { category_id: item.id })}>
        <View style={{ flexDirection: 'row', height: 120, alignItems: 'center', justifyContent: 'center', borderBottomWidth: 1, width: '90%', alignSelf: 'center'}}>
          <Image style={{ width: 200, height:112, resizeMode: 'contain' }} source={{ uri: item.image_url}}/>
          <Text style={{ flex:1, textAlign: 'right' }}>{ item.name }</Text>        
        </View>
      </TouchableOpacity>
    );
  }

  canAddCategory() {
    if(App.loggedInUser != undefined && this.state.new_category_description != "" && this.state.new_category_name != "")
    {
      return true;
    }

    return false;
  }

  onSubmitNewCategory()
  {
    CategoryService.addCategory(this.state.channelData, this.state.new_category_name, this.state.new_category_description, this.state.new_category_visibility);
    this.setState({ new_category_description : "", new_category_visibility: "", new_category_name: ""});
    this.onFocus();
  }

  render() {
    return (
      <ScrollView>
      <View style={styles.container}>
        <View style={{flex:1,backgroundColor: '#EEE', alignItems: 'center', justifyContent: 'center', padding: 8, width: '100%'}}>
          <Text style={{ fontSize: 24}}>{ this.state.channelData?.description}</Text>
        </View>
        <FlatList 
          contentContainerStyle={{width: '100%', alignSelf: 'center'}}
          style={{width: '100%'}}
          data={ this.state.channelData?.categories}
          renderItem={this.renderCategoryItem.bind(this)}
          keyExtractor={(item) => item.name}
        />
      </View>
      { LoggedInUserService.CanCreateCategory(this.state.channelData) &&
          <View style={styles.create_container}>
            <View style={styles.create_top}>
              <Text style={styles.create_text}> Channel Name:</Text>
              <TextInput onChangeText={(text) => { this.setState({new_category_name : text}); }} value={this.state.new_category_name} style={{flex:1, borderWidth: 0.1, height: 32, borderColor: '#BBB', outline: 'none' }}></TextInput>
            </View>
            <View style={styles.create_middle}>
              <Text style={styles.create_text}>Description</Text>
              <TextInput onChangeText={(text) => { this.setState({new_category_description : text}); }} value={this.state.new_category_description} multiline={true} style={{flex:1, borderWidth: 0.1, height: 80, borderColor: '#BBB', outline: 'none' }}></TextInput>
            </View>
            <View style={styles.create_checkbox}>
              <Text style={styles.create_text}>Private</Text>
              <Checkbox onValueChange={(value) => { this.setState({new_category_visibility : value ? "private" : "public"})}} value={this.state.new_category_visibility == "public" ? false : true} ></Checkbox>
            </View>
            <Button disabled={!this.canAddCategory()} title="Add Category" onPress={() => this.onSubmitNewCategory()} />
          </View>
        }
      </ScrollView>
    );
  }
}


