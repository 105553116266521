import React, {Component, Props} from 'react';
import {Video} from 'expo-av';
import {Platform, Alert, Button, FlatList, Image, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import { Dimensions } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as CategoryService from '../services/CategoryService';
import * as Types from '../types';
import * as config from '../config/config.json'
import * as moment from 'moment';
import * as StringFormatting from '../helpers/StringFormatting';
import Blink from '../components/Blink';
import * as App from '../App';
import { styles } from './styles';
import * as LoggedInUserService from '../services/LoggedInUserService';
import * as ElementService from '../services/ElementService';
import * as EncodeService from '../services/EncodeService';
import { BlurView } from 'expo-blur';
import Svg, { Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import DropDownPicker, { ItemType } from 'react-native-dropdown-picker';
import Checkbox from 'expo-checkbox';
//const windowWidth = Dimensions.get('window').width;
//const windowHeight = Dimensions.get('window').height;

export interface CategoryScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
  name: string;
};

type State = {
  ar: number;
  category_data?: Types.TubeCategory;
  active_item?: string;
  active_variant?: string;
  elements_metadata: { [key: string]: Types.ElementMetaData };
  flashVariant: string,
  encodeParams?: Types.EncodeParameters,
  encodeItem?: Types.TubeElement,
  open: boolean,
  value: string,
  encodeQueue: Array<Types.EncodeQueue> | null
}

export default class CategoryScreen extends Component<CategoryScreenProps, State> {

  private _unsubscribeFocus : any = null;
  private video : Video | null = null;
  private flatListRef : FlatList | null = null;
  private encodePresets: Array<Types.EncodePreset> | null = null;
  private encodePresetIds: Array<ItemType<string>> = [];
  constructor(props: CategoryScreenProps) {
    super(props)

    this.state={ar: 1.77777777, elements_metadata: {}, flashVariant: "", open: false, value: "", encodeQueue: null }
  }

  componentDidMount() {
    this._unsubscribeFocus = this.props.navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentWillUnmount() {
    if(this._unsubscribeFocus) {
      this._unsubscribeFocus();
    }
  }

  onFocus() {
    EncodeService.getEncodePresets().then((presets) => {
      this.encodePresets = presets;
      presets.map((value) => {
        this.encodePresetIds.push({ label: value.name, value: value._id });
      })
    });

    this.setState({elements_metadata: {}, category_data: undefined, active_item: undefined, active_variant: undefined, flashVariant: ""})
    if(this.props.route.params) {
      CategoryService.fetchCategoryData(this.props.route.params.category_id).then(
        (data) => {
          this.setState({ category_data: data});
          this.props.navigation.setOptions({ title: data.name, headerLeft: () => 
            <TouchableOpacity onPress={() => this.props.navigation.navigate("Channel", { id: data.channel_id})}><Image style={{ marginLeft: 16, width: 24, height: 24}} source={{"uri":config.image_endpoint + "/back_arrow"}}/></TouchableOpacity>
          });
        },
        (error) => {
          console.log("Error: ", error)
        }
      )
    }
  }

  renderComment(item: Types.TubeElement, index: number) {
    
    const dateCreated = new Date(item.comments[index].created_at);
    const timeSince = moment.utc(dateCreated).local().startOf('seconds').fromNow();

    return (
      <View key={item.id + (8+index)}>
        <View key={item.id + (9+index)} style={{ borderBottomColor: 'black', borderBottomWidth: 1}}/>
        <View style={{flexDirection: 'row', alignContent: 'center'}}>
          <View style={{justifyContent: 'center'}}>
            <Text style={{ fontSize: 18, fontWeight: "bold", justifyContent: 'center'}}>
              {item.comments[index].author} -
            </Text>
          </View>
          <View style={{justifyContent: 'center'}}>
            <Text style={{ paddingLeft: 4, fontSize: 12, justifyContent: 'center'}}>
              {timeSince}
            </Text>
          </View>
        </View>
        
        <Text key={item.id + (11+index)}style={{marginLeft: 32}}>{item.comments[index].text}</Text>
        
      </View>
    )
  }

  renderComments(item: Types.TubeElement) {
    var rows = [];
    for (var commentIndex=0; commentIndex<item.comments.length; ++commentIndex) {
        rows.push(this.renderComment(item, commentIndex));
    }

    return (<View key={item.id + "7"} style={{ backgroundColor: '#FFF'}}>
    { rows }
  </View>)
  }

  createDefaultEncodeParams(item: Types.TubeElement) : Types.EncodeParameters{

    return {
      element_id: item.id,
      framerate: 29.97,
      height: 1080,
      preset_id: this.encodePresets ? this.encodePresets[0]._id : "",
      quality: 25,
      variant_name: ""
    }
  }
  onToggleAddComment(item: Types.TubeElement) {
    if(this.state.elements_metadata[item.id])
    {
      this.state.elements_metadata[item.id].addCommentCollapsed = !this.state.elements_metadata[item.id].addCommentCollapsed;
    }
    else {
      this.state.elements_metadata[item.id] = { addCommentCollapsed: true, commentsCollapsed: false, encodeCollapsed: false}
    }
    this.forceUpdate();
  }

  onToggleShowComments(item: Types.TubeElement) {
    if(this.state.elements_metadata[item.id])
    {
      this.state.elements_metadata[item.id].commentsCollapsed = !this.state.elements_metadata[item.id].commentsCollapsed;
    }
    else {
      this.state.elements_metadata[item.id] = { addCommentCollapsed: false, commentsCollapsed: true, encodeCollapsed: false}
    }
    this.forceUpdate();
  }

  onToggleEncode(item: Types.TubeElement) {
    this.setState({ encodeQueue: null });
    EncodeService.fetchEncodeQueueForElement(item.id).then(
      (queue) => {
        this.setState({ encodeQueue: queue });
        console.log("Queue: ", queue);
        //this.forceUpdate();
      }
    );
    this.setState({ encodeItem: item, encodeParams: this.createDefaultEncodeParams(item)});
  }

  hasValidCommentData(commentData: Types.ElementMetaData) {
    return commentData.newCommentText && commentData.newCommentText.length > 0 && commentData.newCommentAuthor && commentData.newCommentAuthor.length > 0;
  }

  onAddVariant() {
    var encodeParams = this.state.encodeParams;

    if(encodeParams && 
      encodeParams.element_id && encodeParams.element_id != "" &&
      encodeParams.preset_id && encodeParams.preset_id != "" &&
      encodeParams.variant_name != "" &&
      encodeParams.quality < 50 && encodeParams.quality > 10
      ){
        ElementService.encodeVideo(encodeParams);
    }
    else {
      Alert.alert("Check parameters. Variant request not sent to server.");
      console.error("Didn't send variant request to server: ", encodeParams);
    }

    this.setState({ encodeParams: undefined});
  }

  onSubmitComment(item: Types.TubeElement) {
    const elementId = item.id;
    const commentText = this.state.elements_metadata[item.id].newCommentText;
    const commentAuthor = this.state.elements_metadata[item.id].newCommentAuthor;
    if(commentAuthor && commentAuthor.length > 0 && commentText && commentText.length > 0 && elementId) {
      const newComment : Types.TubeComment = {
        author: commentAuthor,
        text: commentText,
        created_at: 0
      }

      CategoryService.addComment(newComment, elementId);
    }
    
    this.state.elements_metadata[item.id].newCommentText = "";
    this.state.elements_metadata[item.id].newCommentAuthor = "";
    this.forceUpdate();
  }
  
  onItemPressed(item: Types.TubeElement, variant: string) {
    
    const itemHasVariants = item.variants && item.variants.length > 1;

    if(variant == 'none' && itemHasVariants) {
      this.setState({flashVariant: item.id})
      setTimeout(() => {
        if(this.state.flashVariant === item.id) {
          this.setState({flashVariant: ""})
        }
      }, 1000);
    }
    else {
      this.setState({ active_item: item.id, active_variant: variant })
    }    
  }

  renderVariants(item: Types.TubeElement) {

    var columns = [];
    for (var variantIndex=0; variantIndex<item.variants.length; ++variantIndex) {

        var variantData = item.variants[variantIndex].split('|');
        var name = 'Filesize';
        var filesize = 0;
        if(variantData.length == 2) {
          
          if(variantData[0] !== "none") {
            name = variantData[0];
          }
          filesize = parseInt(variantData[1]);
        }
        columns.push(this.renderVariant(item, name, filesize));
    }

    return (
      <>
      { this.state.flashVariant == item.id &&
          <Blink iterations={10} blinkkey={item.id + 'variants'} duration={100} style={{ backgroundColor: '#FDD', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            { columns }
          </Blink>
          ||
          <View key={item.id + 'variants'} style={{ backgroundColor: '#FDD', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            { columns }
          </View>
      }
          <View style={{width: '100%'}}>
            { !this.state.encodeParams &&
              <TouchableOpacity onPress={() => { this.onToggleEncode(item)}}>
                <Text>Add Variant</Text>
              </TouchableOpacity>
            }
          </View>
        
      </>
    )    
  }

  renderVariant(item: Types.TubeElement, name: string, filesize: number) {
    return (
      <TouchableOpacity key={item.id + name + "TO"} onPress={ () => this.onItemPressed(item, name === 'Filesize' ? 'none' : name) }>
        <Text key={item.id + name} style={{padding: 4, flex:1, borderWidth: 0.1, borderColor: '#040'}}><strong>{name}</strong> ({StringFormatting.getReadableFileSizeString(filesize)})</Text>
      </TouchableOpacity>
    )
  }

  renderVideoElement(item: Types.TubeElement) {

    const commentCount = item.comments ? item.comments.length : 0;

    const dateCreated = new Date(item.created_at);
    const created_string = moment.utc(dateCreated).local().startOf('seconds').fromNow();

    //const nickname = App.loggedInUser ? App.loggedInUser.nickname : "";

    return (
      <View key={item.id + "4"} style={{ justifyContent: 'center'}}>
        <View style={{ width: '100%', alignItems: 'center', flexDirection: 'row' }}>
          <Text style={{flex: 1, fontSize: 20}}>
            { item.name }
          </Text>
          <Text style={{fontSize: 14, textAlign: 'right', alignSelf: 'flex-end'}}>
            Uploaded: { created_string }
          </Text>
        </View>
        { this.state.active_item === item.id && 
          <Video
            source={{uri: `${config.video_endpoint}/${item.id}_${this.state.active_variant}.${item.extension}`}}
            rate={1.0}
            ref={(ref) => this.video = ref}
            volume={1.0}
            isMuted={false}
            resizeMode="contain"
            shouldPlay={false}
            isLooping={false}
            useNativeControls
            style={{ alignSelf: 'center', height: 360, width: 640, backgroundColor: '#FFE', borderWidth: 0.1, borderColor: '#F55'}}
            onReadyForDisplay={(event) => { if(event?.naturalSize?.width) this.setState({ar: event.naturalSize.width/event.naturalSize.height})}}
          >
            Video playback not supported!
          </Video>
        }
        { this.state.active_item != item.id && 
          <TouchableOpacity style={{ backgroundColor: '#EEE'}} onPress={ () => this.onItemPressed(item, 'none') }>
            <Image key={item.id + "5"} source={{uri: `${config.image_endpoint}/${item.id}`}} style={{ width: 640, height: 360, resizeMode: 'contain', borderWidth: 0.1, borderColor: '#CCC'}} />
          </TouchableOpacity>
        }
        { this.renderVariants(item) }
        <View style={{ width: '100%', alignItems: 'center', flexDirection: 'row', borderBottomWidth: 0.1, borderBottomColor: '#AAA' }}>
          <Text style={{flex:1, fontSize: 20, textAlign: 'center'}}>
            { item.caption }
          </Text>  
        </View>
        
        { !this.state.elements_metadata[item.id]?.commentsCollapsed && 
          <View>
            <TouchableOpacity onPress={() => this.onToggleShowComments(item)}>
              <Text style={{fontSize: 16}}>
                Comments({commentCount}): ▼
              </Text>
            </TouchableOpacity>
          </View>
        }
        { this.state.elements_metadata[item.id]?.commentsCollapsed && 
          <View key={item.id + "6"} style={{backgroundColor: '#FFF'}}>
            <TouchableOpacity onPress={() => this.onToggleShowComments(item)}>
              <Text style={{fontSize: 16}}>
                Comments({commentCount}): ▲
              </Text>
            </TouchableOpacity>
            { this.renderComments(item) }
          </View>
        }
        { this.state.elements_metadata[item.id]?.addCommentCollapsed && 
          <View>
            <TouchableOpacity onPress={() => this.onToggleAddComment(item)}>
              <Text>Add comment: ▲</Text>
            </TouchableOpacity>
            { App.loggedInUser &&
              <>
              <View style={{flexDirection: 'row', borderTopColor: '#777', borderTopWidth: 1, paddingVertical: 2}}>
                <Text style={{width: 140}}>Commenting as {App.loggedInUser.nickname}</Text>
              </View>
              <View style={{flexDirection: 'row', width: '100%', marginVertical: 4}}>
                <Text style={{width: 140}}>Comment:</Text>
                <TextInput onChangeText={(text) => { this.state.elements_metadata[item.id].newCommentText = text; this.forceUpdate(); }} value={this.state.elements_metadata[item.id].newCommentText} multiline={true} style={{flex:1, borderWidth: 0.1, height: 80, borderColor: '#BBB', outline: 'none' }}></TextInput>
              </View>
              <Button disabled={!this.hasValidCommentData(this.state.elements_metadata[item.id])} title="Submit" onPress={() => this.onSubmitComment(item)} />
              </>
            }
            { App.loggedInUser == undefined &&
              <View style={{flexDirection: 'row', borderTopColor: '#777', borderTopWidth: 1, paddingVertical: 2}}>
                <Text style={{width: 140}}>You have to be logged in to comment!</Text>
              </View>
            }
          </View>
        }
        { !this.state.elements_metadata[item.id]?.addCommentCollapsed && 
          <View>
            <TouchableOpacity onPress={() => this.onToggleAddComment(item)}>
              <Text>Add comment: ▼</Text>
            </TouchableOpacity>
          </View>
          
        }
        <View style={{borderBottomWidth: 2, borderBottomColor: '#555'}}></View>
      </View>
    )
  }

  renderPresetPicker() {
    if(this.encodePresets) {
      var rows = [];
      for (var presetIndex=0; presetIndex<this.encodePresets.length; ++presetIndex) {
          rows.push(this.renderPreset(this.encodePresets[presetIndex]));
      }

      return (
        <ScrollView style={{height: 320}}>
          <View style={{ backgroundColor: '#FFF', flex: 1}}>
            { rows }
          </View>
        </ScrollView>
      )
    }

  /*  return (

    <DropDownPicker
                    open={this.state.open}
                    value={this.state.value}
                    items={this.encodePresetIds}
                    setOpen={this.setOpen.bind(this)}
                    setValue={this.setValue.bind(this)}
                    theme="DARK"
                    mode="BADGE"
                    
                    style={{ height: 24, width: 400 }}
                  />
    )*/
  }

  renderPreset(preset: Types.EncodePreset) {
    console.log("preset: ", preset)
    return (
      <View key={preset._id} style={{flexDirection: 'row'}}>
        <View style={{ width: 64, borderColor: '#000', borderWidth: 1, justifyContent: 'center'}}>
          <Checkbox onChange={ (event) => { event.nativeEvent.value && (this.state.encodeParams.preset_id = preset._id); this.forceUpdate() } } value={this.state.encodeParams?.preset_id === preset._id} style={{ width: 32, height: 32, alignSelf: 'center'}}></Checkbox>
        </View>
        <View style={{ padding: 4, flex: 1, borderColor: '#000', borderWidth: 1}}>
          <Text style={{ fontWeight: 'bold'}}>{ preset.name }</Text>
          <Text style={{ fontSize: 12}}>{ preset.paramstring }</Text>
        </View>
      </View>
    )
  }

  renderEncodeQueue() {
    var rows = [];
    if(this.state.encodeQueue) {
      for (var presetIndex=0; presetIndex<this.state.encodeQueue.length; ++presetIndex) {
          rows.push(this.renderEncodeQueueEntry(this.state.encodeQueue[presetIndex]));
      }
    }

    return (
      <ScrollView style={{height: 320}}>
        <View style={{ backgroundColor: '#FFF', flex: 1}}>
          { rows }
        </View>
      </ScrollView>
    )
  }

  renderEncodeQueueEntry(entry: Types.EncodeQueue) {
    return (
      
        
        <View key={entry.id} style={{ flexDirection: 'row', width: '100%', padding: 4, flex: 1, borderColor: '#000', borderWidth: 1}}>
          <Text style={{ fontWeight: 'bold'}}>{ entry.variant_name }</Text>
          <Text> | Quality: { entry.quality } | Status: { entry.status }</Text>
        </View>
      
    )
  }

  renderElement({item, index}: { item: Types.TubeElement, index: number}) {
    return (
      <View key={item.id + "1"}>
        { /*<Button title="Next" onPress={() => { if(this.flatListRef && this.state.category_data?.elements.length && index < this.state.category_data?.elements.length-1 ) this.flatListRef.scrollToIndex({ viewOffset: 0, animated: true, index: index+1 })}} /> */ }
        <View key={item.id + "2"} style={{flex:1,backgroundColor: '#EEE', alignItems: 'center', justifyContent: 'center'}}>
          { item.type === 1 && this.renderVideoElement(item) }
        </View>
      </View>
      )
  }

  onAddElementClicked()
  {
    console.log("Category: ", this.state.category_data)
    this.props.navigation.navigate("CategoryVideoUpload", { category_id: this.state.category_data?.id});
  }

  canAddElement()
  {
    return LoggedInUserService.CanCreateElement(this.state.category_data);
  }

  canEncodeElement()
  {
    return LoggedInUserService.CanEncodeElement(this.state.category_data);
  }

  setValue(callback: any) {
    this.setState(state => ({
      value: callback(state.value)
    }));
  }
  setOpen(open: any) {
    this.setState({
      open
    });
  }

  render() {
    const FROM_COLOR = 'rgba(255, 255, 255, 0.5)';
    const TO_COLOR = 'rgba(0,52,34, 0.5)';

    return (
      <>
        { this.state.encodeParams &&
        
          <BlurView intensity={80} style={{ justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, top: 0, zIndex: 1000, width: '100%', height: '100%' }}>
            <View style={{width: 800, borderRadius: 24, alignSelf: 'center', backgroundColor: 'rgba(52, 52, 52, 0.8)'}}>
              
              <View style={{position: 'absolute', width: '100%', height: '100%'}}>
                <Svg height="100%" width="100%" style={ StyleSheet.absoluteFillObject }>
                  <Defs>
                      <LinearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
                          <Stop offset="0" stopColor={ FROM_COLOR }/>
                          <Stop offset="1" stopColor={ TO_COLOR }/>
                      </LinearGradient>
                  </Defs>
                  <Rect rx={24} width="100%" height="100%" fill="url(#grad)"/>
                </Svg>
              </View>
              <View style={{flex: 1}}>
                <View style={{ margin: 24, alignItems: 'center', justifyContent: 'center'}}>
                  <Text style={{ fontSize: 24 }}>Add Variant to {this.state.encodeItem?.name}</Text>
                </View>
                <View style={{ alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', width: '80%', marginVertical: 4}}>
                  <Text style={{width: 120}}>Variant Name</Text>
                  <TextInput onChangeText={(text) => { this.state.encodeParams.variant_name = text; this.forceUpdate(); }} value={this.state.encodeParams?.variant_name} style={{flex:1, borderWidth: 0.1, borderColor: '#BBB', outline: 'none' }}></TextInput>
                </View>
                <View style={{ alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', width: '80%', marginVertical: 4}}>
                  <Text style={{width: 120}}>Height</Text>
                  <TextInput onChangeText={(text) => { this.state.encodeParams.height = text ? parseInt(text.replace(/[^0-9]/g, '')) : ""; this.forceUpdate(); }} value={this.state.encodeParams?.height.toString()}  style={{flex:1, borderWidth: 0.1, borderColor: '#BBB', outline: 'none' }}></TextInput>
                </View>
                <View style={{ alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', width: '80%', marginVertical: 4}}>
                  <Text style={{width: 120}}>Quality</Text>
                  <TextInput onChangeText={(text) => { this.state.encodeParams.quality = text ? parseInt(text.replace(/[^0-9]/g, '')) : ""; this.forceUpdate(); }} value={this.state.encodeParams?.quality.toString()}  style={{flex:1, borderWidth: 0.1, borderColor: '#BBB', outline: 'none' }}></TextInput>
                </View>
                <View style={{ maxHeight: 240,alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', width: '80%', marginVertical: 4}}>
                  <Text style={{width: 120}}>Preset</Text>
                  <View style={{flex: 1}}>
                    { this.renderPresetPicker() }
                  </View>
                  
                </View>
                <View style={{ maxHeight: 120, alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', width: '80%', marginVertical: 4}}>
                  <Text style={{width: 120}}>Variants</Text>
                  <View style={{flex: 1}}>
                    { this.renderEncodeQueue() }
                  </View>
                  
                </View>
                
              </View>
              
                <View style={{ flexDirection: 'row', justifyContent: 'center', margin: 24}}>
                  <TouchableOpacity onPress={() => this.onAddVariant()}><View style={styles.button}><Text>Add Variant</Text></View></TouchableOpacity>
                  <TouchableOpacity onPress={() => this.setState({ encodeParams: undefined})}><View style={styles.button}><Text>Cancel</Text></View></TouchableOpacity>
                </View>
              </View>
          </BlurView>
        }
          <View style={styles.container}>
            <FlatList 
              ref={(ref) => { this.flatListRef = ref; }}
              contentContainerStyle={{width: '100%', height: '100%', alignSelf: 'center'}}
              style={{width: '100%'}}
              data={ this.state.category_data?.elements}
              renderItem={this.renderElement.bind(this)}
              keyExtractor={(item) => item.id}
            />
            { this.canAddElement() &&
            <View style={styles.create_container}>
              <Button disabled={!this.canAddElement()} title="Add Element" onPress={() => this.onAddElementClicked()} />
            </View>
            }
          </View>
        </>
    );
  }
}
// <ScrollView style={{height: '100%'}}>
// </ScrollView>
