import { StyleSheet} from 'react-native';

export const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#DDD',
    },
    create_container: {
      alignItems: 'center', 
      justifyContent: 'center', 
      borderBottomWidth: 1, 
      width: '90%', 
      alignSelf: 'center'
    },
    create_top: {
        flexDirection: 'row', 
        paddingTop: 8, 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '90%', 
        alignSelf: 'center'
    },
    create_middle: {
        flexDirection: 'row', 
        paddingVertical: 8, 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: '90%', 
        alignSelf: 'center'
    },
    create_checkbox: {
        flexDirection: 'row', 
        paddingVertical: 8, 
        alignItems: 'center', 
        justifyContent: 'flex-start', 
        width: '90%', 
        alignSelf: 'center'
    },
    create_text: {
        width: 120
    },
    button: {
      justifyContent: 'center', 
      alignItems: 'center', 
      height: 32, 
      width: 120, 
      backgroundColor: '#666',
      marginHorizontal: 16,
      borderRadius: 8
    }
    
  });