import { client } from './../App';
import * as Types from '../types';
import * as config from '../config/config.json';
import { gql } from '@apollo/client';

const getEncodeQueue = gql`
      query ($element_id: String!) {
        getEncodeQueueForElement(element_id: $element_id) {
            status
            height
            quality
            framerate
            variant_name
            preset
          }
      }
    `;

export async function getEncodePresets(): Promise<Array<Types.EncodePreset>> {

    return new Promise((resolve, reject) => {
        fetch(`${config.tubeenc_url}/encode/presets`).then((response) => {
            response.json().then((value) => {
                resolve(value);
            },
            (err) => {
                reject(err);
            })
        },
        (err)=> {
            reject(err);
        })
    })
}

export async function fetchEncodeQueueForElement(element_id: string): Promise<Array<Types.EncodeQueue>> {

    return new Promise<Array<Types.EncodeQueue>>(async (resolve, reject) => {
        client.query({ query: getEncodeQueue, variables: { element_id: element_id}, fetchPolicy: 'no-cache'})
        .then((data) => {
            console.log("Queue:");
            console.log(data.data);
            resolve(data.data.getEncodeQueueForElement);
        },
        (err) => {
            console.log("fetchEncodeQueueForElement error: ", err);
            reject(err);
        });
        
        //request(config.graphql_endpoint, categoryQuery, { id: id}).then((data) => {
            //console.log("cat_dat: ", data.category);
         //   resolve(data.category);
        //})
    })
}

//client.mutate( { mutation: encodeVideoMutation, variables: { id: element_id, text: comment.text, author: comment.author}}).then((data) => {
//query category($id: String!) {
 //   category(id: $id) {

