import React, { Component } from "react";
import { Animated, View, ViewStyle } from "react-native";

type Props = {
    duration: number;
    style?: ViewStyle;
    blinkkey: string;
    iterations: number;
}
export default class Blink extends Component<Props> {

    private fadeAnimation : Animated.Value;

    constructor(props : Props) {
        super(props);
        this.fadeAnimation = new Animated.Value(0);
    }

    componentDidMount() {
        Animated.loop(
            Animated.sequence([
                Animated.timing(this.fadeAnimation, {
                    toValue: 0,
                    duration: this.props.duration,
                    useNativeDriver: true
                }),
                Animated.timing(this.fadeAnimation, {
                    toValue: 1,
                    duration: this.props.duration,
                    useNativeDriver: true
                })
            ]), {iterations: this.props.iterations }
        ).start();
    }

    render() {
        return (
            <Animated.View key={this.props.blinkkey + "AnimView"} style={[{ opacity: this.fadeAnimation}, this.props.style]}>
                { this.props.children }
            </Animated.View>
        )
    }
}