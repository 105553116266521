import React, {Children, Component, Props} from 'react';
import {Video} from 'expo-av';
import {Button, FlatList, Image, ScrollView, Share, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import { Dimensions } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as config from '../config/config.json';
import * as CategoryService from '../services/CategoryService';

//import * as CategoryService from '../services/CategoryService';
//import * as Types from '../types';

import * as DocumentPicker from 'expo-document-picker';
import { types } from '@babel/core';
import { shouldWriteResult } from '@apollo/client/core/QueryInfo';

export interface CategoryVideoUploadScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
  name: string;
};

type State = {
  uploading: boolean;
  element_name: string;
  element_caption: string;
  uploadProgress: number;
  file: any;
}

export default class CategoryVideoUploadScreen extends Component<CategoryVideoUploadScreenProps, State> {

  constructor(props: CategoryVideoUploadScreenProps) {
    super(props)

    this.state={ uploading: false, element_name: "", element_caption: "", uploadProgress: 0, file: "" };
  }

  componentDidMount() {
    
    
  }

  pickFile() {
    console.log("pickFile: ");
    DocumentPicker.getDocumentAsync({ type: "video/*", multiple: false, copyToCacheDirectory: false}).then((value: DocumentPicker.DocumentResult) =>
    {
      if(value.type === 'success') {

        console.log("File: ", value.file);
        this.setState({ file: value.file});
      }
    });
  }

  async uploadFileAsync(cat_id: string) : Promise<void> {

    const xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {

      CategoryService.getElementUploadToken(cat_id, this.state.element_name, this.state.element_caption).then(
        (upload_token) => {
          const data = new FormData();
        
          data.append('video', this.state.file);
          data.append('element_name', this.state.element_name)
          data.append('caption', this.state.element_caption);

          xhr.upload.addEventListener('progress', (event) => {
            if(event.lengthComputable) {
              const uploadProgress = event.loaded / event.total;
              this.setState({uploadProgress: uploadProgress});
            }
          })
    
          xhr.addEventListener('error', (event) => {
            reject(event);
          });
    
          xhr.addEventListener('loadend', (data) => {
          });
    
          xhr.addEventListener('readystatechange', (response) => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
              console.log("ret: ", xhr.responseText);
              console.log("res: ", response);

              resolve();
            }
          });

          xhr.onreadystatechange = function() {
            
          };
    
          xhr.addEventListener('timeout', () => {
            reject("timeout");
          });
          
          xhr.open("POST", config.upload_endpoint, true);
          xhr.setRequestHeader("Authorization", "Bearer " + upload_token);
          //xhr.setRequestHeader("Accept", 'application/json')
          //xhr.setRequestHeader("Content-Type", 'multipart/form-data; boundary=--------------------------946049678713301377105154');
          xhr.send(data);
        },
        (err) => {
          // TODO: show error
          console.error("GetUploadToken failed: ", err);
        }
      );
    });
  }

  async uploadFiles() {

    this.setState({ uploading: true});
    const cat_id = this.props.route.params?.category_id;

    if(cat_id) {
      await this.uploadFileAsync(cat_id);
    }
    this.setState({ uploading: false});
  }

  onCaptionChanged(text: string) {
    this.setState({ element_caption: text})
  }

  onNameChanged(text: string) {
    this.setState({ element_name: text})
  }

  renderInputViews() {

    var inputViews = [];

    return (
        <View key={"inputview_view1"} style={{ borderWidth: 0.1, width: 640 }}>
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 120}}>File:</Text>
            <Text key={"inputview_file"} style={{flex:1, backgroundColor: '#FFF', borderWidth: 0.1, borderColor: '#BBB', outline: 'none'}}>{ this.state.file?.name }</Text>
            <TouchableOpacity onPress={ () => this.pickFile()}><Text style={{backgroundColor: '#BBB', borderColor: '#111', borderWidth: 0.1, borderRadius: 4}}>Choose file...</Text></TouchableOpacity>
          </View>
          <Text key={"inputview_progress"}>Progress: { (this.state.uploadProgress * 100).toFixed(2)}</Text>
        </View>
      )
  }

  render() {
    return (
      <>
      { this.state.uploading &&
        <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', position: "absolute", width: "100%", height: "100%", zIndex: 1000, borderWidth: 1, borderColor: "#555", opacity: 0.5, backgroundColor: "#000"}} >
          <View style={{ width: 200, height: 80, alignItems: 'center', flexDirection: 'column', padding: 8, borderRadius: 16, backgroundColor: '#FFF', justifyContent: 'center', alignSelf: 'center'}}>
            <Text style={{ flex: 1, color: '#000', fontSize: 24 }}>{ this.state.uploadProgress < 1 ? 'Uploading...' : 'Processing...'}</Text>
            { this.state.uploadProgress < 1 &&
              <Text style={{ color: '#000', fontSize: 24 }}>{(this.state.uploadProgress*100).toFixed(2)} %</Text>
            }
          </View>
        </View>
      }
      <ScrollView>
        <View style={styles.container}>
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 120}}>Element Name:</Text>
            <TextInput style={{flex: 1, backgroundColor: '#FFF', borderWidth: 0.1, borderColor: '#BBB', outline: 'none'}} value={this.state.element_name} onChangeText={(text) => this.setState({ element_name: text })}></TextInput>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 120}}>Element Caption:</Text>
            <TextInput style={{flex: 1, backgroundColor: '#FFF', borderWidth: 0.1, borderColor: '#BBB', outline: 'none'}} value={this.state.element_caption} onChangeText={ (text) => this.setState({ element_caption: text })}></TextInput>
          </View>
          { this.renderInputViews() }
          <Button title='Upload' onPress={ () => this.uploadFiles()}/>
          
        </View>
      </ScrollView>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DDD',
  },
  
});
