import React, {Component} from 'react';
import {Button, ScrollView, StyleSheet, Text, TextInput, View} from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as CategoryService from '../services/CategoryService';

//import * as CategoryService from '../services/CategoryService';
//import * as Types from '../types';


export interface EncodeElementScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
  name: string;
};

type State = {
  variant_name: string;
  encode_preset: string;
  quality: number;
  processing: boolean;
}

export default class EncodeElementScreen extends Component<EncodeElementScreenProps, State> {

  constructor(props: EncodeElementScreenProps) {
    super(props)

    this.state={ variant_name: "", encode_preset: "", quality: 21, processing: false };
  }

  componentDidMount() {
  }

  onVariantNameChanged(text: string) {
    this.setState({ variant_name: text})
  }

  encodeElement() {
    //this.props.route.params?.element_id
  }

  render() {
    return (
      <>
      { this.state.processing &&
        <View style={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', position: "absolute", width: "100%", height: "100%", zIndex: 1000, borderWidth: 1, borderColor: "#555", opacity: 0.5, backgroundColor: "#000"}} >
          <View style={{ width: 200, height: 80, alignItems: 'center', flexDirection: 'column', padding: 8, borderRadius: 16, backgroundColor: '#FFF', justifyContent: 'center', alignSelf: 'center'}}>
            <Text style={{ flex: 1, color: '#000', fontSize: 24 }}>Processing...</Text>
            
          </View>
        </View>
      }
      <ScrollView>
        <View style={styles.container}>
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 120}}>Variant Name:</Text>
            <TextInput style={{flex: 1, backgroundColor: '#FFF', borderWidth: 0.1, borderColor: '#BBB', outline: 'none'}} value={this.state.variant_name} onChangeText={(text) => this.setState({ element_name: text })}></TextInput>
          </View>
          <View style={{flexDirection: 'row'}}>
            <Text style={{width: 120}}>Encode Preset:</Text>
            <TextInput style={{flex: 1, backgroundColor: '#FFF', borderWidth: 0.1, borderColor: '#BBB', outline: 'none'}} value={this.state.encode_preset} onChangeText={ (text) => this.setState({ element_caption: text })}></TextInput>
          </View>

          <Button title='Upload' onPress={ () => this.encodeElement()}/>
          
        </View>
      </ScrollView>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DDD',
  },
  
});
