import React, {Component, Props} from 'react';
import {Video} from 'expo-av';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import { Dimensions } from 'react-native';
import { RouteProp } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export interface VideoScreenProps {
  navigation: NativeStackNavigationProp<any,any>;
  route: RouteProp<any, any>;
  name: string;
};

type State = {
  ar: number,
  video_uri: string
}
export default class VideoScreen extends Component<VideoScreenProps, State> {

  constructor(props: VideoScreenProps) {
    super(props)

    this.state={ar: 1.77777777, video_uri: ""}
  }

  componentDidMount() {
    
    //'https://tube.jckraemer.de/video/' + this.props.route.params.name + '.mp4'
  }
  renderComment(author: string, message: string) {

    return (
      <View>
        <View style={{ borderBottomColor: 'black', borderBottomWidth: 1}}/>
        <Text style={{ fontSize: 18, fontWeight: "bold"}}>
          {author}
        </Text>
        <Text style={{marginLeft: 32}}>{message}</Text>
        
      </View>
    )
  }

  renderComments() {
    var rows = [];
    for (var i = 0; i < 10; i++) {
        
        rows.push(this.renderComment("Author " + (i + 1), "Comment. Blah blah blah. Great stuff: " + (i + 1)));
    }

    return (<View style={{ backgroundColor: '#FFF'}}>
    { rows }
  </View>)
  }
  render() {
    return (
      <ScrollView>
      <View style={styles.container}>
        <View style={{flex:1,backgroundColor: '#EEE', alignItems: 'center', justifyContent: 'center'}}>
          <Video
            
            source={{uri: this.state.video_uri}}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            resizeMode="contain"
            shouldPlay={false}
            isLooping={false}
            useNativeControls
            style={{height: windowHeight-270, width: (windowHeight-270)*this.state.ar, backgroundColor: '#FF0'}}
            onReadyForDisplay={(event) => { if(event?.naturalSize?.width) this.setState({ar: event.naturalSize.width/event.naturalSize.height})}}
          />
        </View>
        <View style={{height: 100, width: (windowHeight-270)*this.state.ar,backgroundColor: '#FFF'}}>

        </View>
        <View style={{width: (windowHeight-270)*this.state.ar,backgroundColor: '#FFF'}}>
          { this.renderComments() }
        </View>
      </View>
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#DDD',
  },
  
});
