import * as Types from '../types';
import * as config from '../config/config.json'
import { client } from './../App';
import { gql } from '@apollo/client';

/*
const basicChannelQuery = gql`
    query channel($name: String!) {
        channel(name: $name) {
            id
            name
            description
            categories {
                id
                name
                description
                image_url
            }
        }
    }
`;
*/
const basicChannelQueryByID = gql`
    query channel_by_id($id: String!) {
        channel_by_id(id: $id) {
            id
            name
            visibility
            owner
            {
                _id
                nickname
            }
            description
            categories {
                id
                name
                owner
                {
                    _id
                    nickname
                }
                description
                visibility
                image_url
            }
        }
    }
`;

const allChannelsQuery = gql`
    query {
        all_channels {
            id
            name
            owner
            {
                _id
                nickname
            }
            visibility
            description
        }
    }
`;

const addChannelMutation = gql`
    mutation add_channel($name: String!, $description: String!, $visibility: String) {
        add_channel(name: $name, description: $description, visibility: $visibility) {
            name
        }
    }
`;

export async function fetchChannels() : Promise<Array<Types.TubeChannel>> {
    return new Promise<Array<Types.TubeChannel>>((resolve, reject) => {
        client.query({ query: allChannelsQuery}).then((data) => {
            resolve(data.data.all_channels);
        })
    })
}

export async function addChannel(name: string, description: string, visibility: string) : Promise<Types.TubeChannel> {
    return new Promise<Types.TubeChannel>((resolve, reject) => {

        client.mutate( { mutation: addChannelMutation, variables: { name: name, description: description, visibility: visibility}}).then((data) => {
            resolve(data.data.channel);
        })
    })
}

/*
export async function fetchChannelData(name: string): Promise<Types.TubeChannel> {

    return new Promise<Types.TubeChannel>((resolve, reject) => {

        request(config.graphql_endpoint, basicChannelQuery, { name: name}).then((data) => {
            resolve(data.channel);
        })
    })
}*/

export async function fetchChannelDataByID(channel_id: string): Promise<Types.TubeChannel> {

    return new Promise<Types.TubeChannel>((resolve, reject) => {

        client.query({ query: basicChannelQueryByID, variables: { id: channel_id}}).then((data) => {
            resolve(data.data.channel_by_id);
        })
    })
}