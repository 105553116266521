import { TubeChannel, TubeCategory } from './../types';
import * as App from '../App'

export function CanCreateChannel()
{
    if(App.loggedInUser?.roles)
    {
        return App.loggedInUser?.roles.indexOf("superuser") != -1;
    }

    return false;
}

export function CanCreateCategory(channelData? : TubeChannel)
{
    if(channelData?.owner?._id && channelData.owner._id == GetUserID())
    {
        return true;
    }

    return false;
}

export function CanAddVideo(catgeoryData?: TubeCategory)
{
    if(catgeoryData?.owner?._id && catgeoryData.owner._id == GetUserID())
    {
        return true;
    }

    return false;
}

export function CanCreateElement(categoryData?: TubeCategory)
{
    if(categoryData?.owner?._id && categoryData.owner._id == GetUserID())
    {
        return true;
    }

    return false;
}

export function CanEncodeElement(categoryData?: TubeCategory)
{
    if(categoryData?.owner?._id && categoryData.owner._id == GetUserID())
    {
        return true;
    }

    return false;
}

export function GetUserID() : string | undefined
{
    return App.loggedInUser?._id;
}
