import App, { client } from './../App';
import * as Types from '../types';
import { gql } from '@apollo/client';
import * as LoggedInUserService from '../services/LoggedInUserService';

const categoryQuery = gql`
    query category($id: String!) {
        category(id: $id) {
            id
            name
            owner {
                _id
                roles
                nickname
            }
            channel_id
            description
            image_url
            elements {
                id
                name
                caption
                type
                state
                variants
                extension
                created_at
                comments {
                    author
                    text
                    created_at
                }
            }
        }
    }
`;

const addCategoryMutation = gql`
    mutation add_category($channel_id: String!, $name: String!, $description: String!, $visibility: String) {
        add_category(channel_id: $channel_id, name: $name, description: $description, visibility: $visibility) {
            name
        }
    }
`;

const addCommentQuery = gql`
    mutation comment_element($id: String!, $author: String!, $text: String!) {
        comment_element(id: $id, author: $author, text: $text) {
            author
            text
            created_at
        }
    }
`;

const getUploadTokenQuery = gql`
      query ($category_id: String!, $element_name: String!, $element_caption: String!) {
          getuploadtoken(category_id: $category_id, element_name: $element_name, element_caption: $element_caption) {
              token
          }
      }
    `;

export async function fetchCategoryData(id: string): Promise<Types.TubeCategory> {

    return new Promise<Types.TubeCategory>(async (resolve, reject) => {
        client.query({ query: categoryQuery, variables: { id: id}})
        .then((data) => {
            resolve(data.data.category);
        });
        
        //request(config.graphql_endpoint, categoryQuery, { id: id}).then((data) => {
            //console.log("cat_dat: ", data.category);
         //   resolve(data.category);
        //})
    })
}

export async function addComment(comment: Types.TubeComment, element_id: string) {
    return new Promise<Types.TubeCategory>((resolve, reject) => {
        client.mutate( { mutation: addCommentQuery, variables: { id: element_id, text: comment.text, author: comment.author}}).then((data) => {
            resolve(data.data);
        })
    })
}

export async function addCategory(channel_data: Types.TubeChannel | undefined, name: string, description: string, visibility: string) : Promise<Types.TubeChannel> {
    return new Promise<Types.TubeChannel>((resolve, reject) => {

        console.log("owner_id: ", channel_data?.owner._id);
        console.log("user id: ", LoggedInUserService.GetUserID());
        if(channel_data && channel_data.owner._id && channel_data.owner._id == LoggedInUserService.GetUserID())
        client.mutate( { mutation: addCategoryMutation, variables: { channel_id: channel_data.id, name: name, description: description, visibility: visibility}}).then((data) => {
            resolve(data.data.channel);
        })
    })
}

export async function getElementUploadToken(categoryId: string, name: string, caption: string) : Promise<string> {
    
    return new Promise<string>((resolve, reject) => {
        client.query({ query: getUploadTokenQuery, variables: { category_id: categoryId, element_name: name, element_caption: caption}, fetchPolicy: 'no-cache'}).then((data) => {
            resolve(data.data.getuploadtoken.token);
          },
          (err) => {
            reject(err);
          })
    })
    
}