import App, { client } from './../App';
import * as Types from '../types';
import { gql } from '@apollo/client';
import * as LoggedInUserService from '../services/LoggedInUserService';

const encodeVideoMutation = gql`
    mutation encodeVideo($parameters: EncodeParameters!) {
        encodeVideo(parameters: $parameters) 
        
    }
`;


export async function encodeVideo(encodeParams: Types.EncodeParameters) {
    return new Promise<boolean>((resolve, reject) => {
        client.mutate( { mutation: encodeVideoMutation, variables: { parameters: encodeParams }}).then((data) => {
            resolve(data.data);
        })
    })
}

//client.mutate( { mutation: encodeVideoMutation, variables: { id: element_id, text: comment.text, author: comment.author}}).then((data) => {
//query category($id: String!) {
 //   category(id: $id) {